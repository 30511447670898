.bottom-bar-uanl-text {
  text-overflow: ellipsis;
  font-size: 12px;
  //color: #fff;
}

.bottom-bar-subtitle-text {
  font-size: xx-small;
  font-weight: 400;
  //color: #fff;
}

.bottom-bar-sicdt {
  max-width: 24ch;
  text-wrap: wrap;
}

// .bottom-bar {
//   border-top-style: solid;
//   border-top-width: 1px;
//   border-top-color: black;
// }
