@import './../../../index.scss';

.side-bar-container {
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: black;
  //background-color: white !important;
  background-color: $gris;
}

.side-bar-container * {
  color: $blanco;
}

.sidebar-menu-nav-title {
  width: 100%;
}

.sidebar-menu-nav-title.menu-title {
  font-size: 15px !important;
}

.sidebar-menu-nav-title.sub-menu-title {
  font-size: 13px !important;
}

.side-bar-subtitle-text {
  font-size: 12px;
  font-weight: 400;
}

.brand-text.sidebar-brand-text {
  font-size: 45px;
}

.side-bar-welcome-text {
  font-size: 14px;
  font-weight: 400;
  //color: #222 !important;
}

.side-bar-welcome-text > .side-bar-welcome-text-user {
  font-size: 16px;
  font-weight: 800;
}

// Needed to replace the side bar active link
.sidebar-menu-nav-link.active {
  background-color: $primary !important;
}

.menu_logo {
  height: 100%;
  width: 100%;
}

.menu_logo g {
  fill: #e5e5e5;
}

.menu_logo path {
  stroke: #e5e5e5;
  stroke-width: 10px;
}

.sidebar-body {
  width: fit-content !important;
}
