@import './../../index.scss';
.pdf {
  padding: 0%;
  width: 21.59cm;
  height: 27.94cm;
}

h3 {
  text-align: center;
}

.Firma {
  text-align: center;
}

.texto-firma {
  font-size: 90%;
}

footer {
  text-align: center;
}

.tablaCostos {
  border-style: solid !important;
  border: 5px !important;
}

.tablaTitulo p {
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.desgloseTitulo {
  font-weight: bold;
}

.letrasChicas {
  font-size: smaller;
}

thead.report-head,
tr.report-head {
  background-color: #1f3864 !important;
  border-color: black;
  color: white !important;
}

tbody tr {
  border-color: black;
}

tbody.report-table tr:nth-child(even) {
  background-color: #d9e2f3;
}

tbody.report-table > tr:nth-child(odd) > * {
  box-shadow: none !important;
}

.report-table-container {
  font-size: small;
}

.image-header-right {
  padding-left: 60px;
}

table.tablePDF > thead {
  background-color: white !important;
}

@media print {
  .header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
  }
  footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .firma-container {
    page-break-inside: avoid;
  }
}

.firma-container {
  padding-top: 75px;
}

.page-break {
  break-before: always;
}

.pdf header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}
.pdf footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  font-size: small;
}

table {
  page-break-inside: auto;
}
tr {
  page-break-inside: avoid;
  page-break-after: auto;
}
thead {
  display: table-header-group;
}
tfoot {
  display: table-footer-group;
}

.Firma p {
  padding: 0;
  margin: 0;
}
.footer-table-report {
  page-break-after: avoid;
  page-break-inside: avoid;
  page-break-before: avoid;
}

.true-footer p {
  margin: 0;
  padding: 0;
}
