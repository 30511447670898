@import './../../../index.scss';

td.students-row tr {
  border-style: solid !important;
  border: 5px !important;
}
.student-row {
  border-style: solid !important;
  border: 5px !important;
}
