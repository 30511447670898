$font-size-root: 15px;
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

//
$amarillo: #fcc801;
$azul: #0d6efd;
$gris: #232323;
$blanco: #ffffff;
$morado: #4b009b;

// Redefine main colors
//$primary: #0d6efd;
$primary: $azul;
$info: #0dcaf0;
$danger: #dc3545;
$success: #198754;
$secondary: #6c757d;
$warning: #ffc107;

// merge with existing $theme-colors map
$theme-colors: map-merge(
  $theme-colors,
  (
    'primary': $primary,
    // 'secondary': $secondary,
    // 'success': $success,
    // 'warning': $warning,
    // 'danger': $danger,
    // 'info': $info,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
  )
);

@import '~bootstrap/scss/bootstrap';
@import '~react-bootstrap-sidebar-menu/dist/sidebar-menu.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.text-clickable {
  cursor: pointer;
}

//
.main-wrapper.main-wrapper-hub {
  grid-template-areas:
    'sidebar header'
    'sidebar main'
    'bottom bottom';
}

.main-wrapper {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 20fr 1fr;
  grid-template-areas:
    'sidebar header'
    'sidebar main'
    'bottom bottom';
}

.main-header {
  grid-area: header;
  padding: 0.5rem;
}

.main-container {
  grid-area: main;
  overflow: auto;
  padding-top: 1rem;
}

.sidebar-menu {
  padding-top: 0;
  flex-direction: column;
  grid-area: sidebar;
  overflow-y: auto;
}

.bottom-bar {
  grid-area: bottom;
}
//

.brand-text {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.15rem;
}

.white-vertical-bar {
  color: #fff;
  opacity: 1;
}

.black-vertical-bar {
  color: #000;
  opacity: 1;
}

.generic-panel {
  height: 90% !important;
  width: 100% !important;
}

.title-text {
  font-size: calc(25px + 2vmin);
  font-weight: bold;
}

.hub-container {
  /* background-image: url('/public/inv-background.jpeg'); */
  background-color: #ffffff !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.inherit-background-color {
  background-color: inherit;
}

.Toastify__toast-container--top-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.atencion {
  width: 30%;
}

.atencion .fil0 {
  fill: black;
}

.atencion .fil2 {
  fill: #fefefe;
}

.tooltip > .tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid #062e56;
  text-align: left;
}

.tooltip.tooltip-warning > .tooltip-inner {
  border: 1px solid #9b0404;
  color: #a60000;
}
