@import './../../index.scss';

.signup-container {
  /* background-image: url('/public/ImagenNocturna.jpg'); */
  background-color: #e0e0e0;

  /* background-image: url('/public/Flama2.jpg'); */

  /* background-image: url('/public/RectoriaCerca.jpg'); */

  /* background-image: url('/public/RectoriaLejos.jpg'); */

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.texto-info {
  font-size: large;
  font-weight: bold;
}

.panel-signup {
  height: 100%;
  width: 100%;
}

@include media-breakpoint-up(lg) {
  .panel-signup {
    height: fit-content !important;
    width: 50% !important;
  }
}
.closed-convocation-notif {
  background-color: #e74c3c;
  color: white;
  border-radius: 4px;
}
