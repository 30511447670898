#texto-principal {
  margin-top: 0%;
  margin-bottom: 0%;
  margin-left: 0%;
  margin-right: 0%;
}
#firma {
  margin-top: 20px;
}
.atentamente {
  font-weight: bold;
  display: inline;
}
.panel {
  text-align: justify;
  text-justify: inter-word;
  background-color: #e0e0e0;
  padding-top: 10%;
  padding-bottom: 10%;
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 10px;
  /* box-shadow: 5px 5px 5px gray; */
  box-shadow: 3px 6px 24px 0px rgba(0, 0, 0, 0.75);
  /* webkit-box-shadow: 3px 6px 24px 0px rgba(0,0,0,0.75); */
}
.bold-text {
  font-weight: bold;
  display: inline;
}
