@import './../../../index.scss';

.top-bar-subtitle-text {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  height: fit-content;
}

.main-header.top-bar.hub {
  background-color: $amarillo !important;
  //background-color: $amarillo !important;
}

.main-header.top-bar {
  background-color: #fff !important;
}

.top-bar-welcome-text {
  font-size: 14px;
  font-weight: 400;
  color: #222 !important;
}

.top-bar-welcome-text-user {
  font-size: 16px;
  font-weight: 800;
  text-align: end;
}

.text-logout {
  font-size: smaller !important;
  height: fit-content !important;
  width: fit-content !important;
}

.finalized-text {
  text-align: end;
  color: $success;
}

.registration-finalized-text {
  text-align: end;
  color: $danger;
}

// .main-header.top-bar{
//   border-bottom-style: solid;
//   border-bottom-width: 1px;
//   border-bottom-color: black;
// }
