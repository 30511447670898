@import '~bootstrap/scss/bootstrap';

.login-container {
  background-image: url('~/public/FondoFlama2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.login-text {
  font-size: calc(25px + 2vmin);
  font-weight: bold;
}

.inicio-sesion-abajo {
  height: fit-content;
}

.panel-login {
  height: 100%;
  width: 100%;
}

@include media-breakpoint-up(lg) {
  .panel-login {
    height: fit-content !important;
    width: 75% !important;
  }
}

@include media-breakpoint-down(md) {
  .aviso-privacidad-col {
    padding-bottom: 1rem;
  }
}
