@import './../../../index.scss';

.objective-table {
  height: 1px;
} /* Will be ignored, don't worry. */
.objectiveRow {
  height: 100%;
}
.objectivesDateCell {
  height: 100%;
}
