@import './../../../index.scss';

thead {
  background-color: $primary;
  border-color: black;
}
tr.row-objective {
  background-color: #c0e0ff;
  border-color: black;
}
tr.row-activity {
  border-color: black;
}
